import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";
import ClientMediaDownloadPage from "../components/downloads";
import firebase from "src/firebase";

const Downloads = () => {
  // This ensures that Firebase in initialized before the view is loaded.
  const [firebaseReady, setFirebaseReady] = useState(false);

  useEffect(() => {
    // We do not care if the user is signed in or not. Just that we're able to subscribe to the auth listener
    firebase.auth().onAuthStateChanged(() => {
      setFirebaseReady(true);
    });
  }, []);

  if (!firebaseReady) {
    return null;
  }

  return (
    <Router>
      <ClientMediaDownloadPage path="/downloads/:missionID/:accessToken/" />
      <ClientMediaDownloadPage path="/downloads/:missionID/:accessToken/:missionName/" />
      <ClientMediaDownloadPage path="/downloads/:missionID/:accessToken/:missionName/:mapTypes" />
    </Router>
  );
};

export default Downloads;
